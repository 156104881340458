<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList" :showSearch="false" :showHorizontalFilter="false"
      :propsParam="propsParam">
      <div slot="content-buttons">
        <Button _key="btnPatrimonyCreate" title="Patrimônio" classIcon="fas fa-plus-circle" type="primary" size="small"
          :clicked="create" />
      </div>
    </ViewTemplateConfiguration>
    <Modal title="Patrimônio" :width="900" :height="750" v-show="showModal('patrimonyCreateUpdate')">
      <PatrimonyCreateUpdate :productId="productId" />
    </Modal>
    <Modal title="Histórico de Movimentação" :width="750" :height="750" v-show="showModal('historyPatrimony')">
      <HistoryPatrimony />
    </Modal>
  </div>
</template>

<script>

import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import HistoryPatrimony from '../history-patrimony/HistoryPatrimony.vue'
import PatrimonyCreateUpdate from "./PatrimonyCreateUpdate.vue";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "PatrimonyList",
  components: {
    ViewTemplateConfiguration,
    Modal,
    Button,
    PatrimonyCreateUpdate, HistoryPatrimony
  },
  props: {
    productId: String,
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/stock/patrimony/get-all",
        urlDeleteAllApi: "/api/v1/stock/patrimony/delete",
        headerTable: [
          {
            field: "barCode",
            title: "Código",
            type: "link",
            eventName: "updatePatrimony",
          },
          {
            field: "serialNumber",
            title: "Nº Série",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "purchaseDate",
            title: "Data de Compra",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "statusName",
            title: "Status",
            type: "class",
            fieldComparison: "statusName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center badge badge-success",
                fieldComparison: "Disponível",
              },
              { classCss: "text-center badge badge-primary", fieldComparison: "Locado" },
              {
                classCss: "text-center badge badge-warning",
                fieldComparison: "Manutenção",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Inativo",
              },
            ],
          },
          {
            type: "button",
            typeButton: "default",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            button: {
              title: "Histórico",
              classIcon: "fa-solid fa-clock-rotate-left",
              type: "info",
              size: "small",
              eventName: "historyPatrimony",
            },
          },
        ],
      },
      propsParam: {
        any: this.productId,
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    create() {
      this.openModal("patrimonyCreateUpdate");
      this.addEvent({ name: "patrimonyCreate" });
      this.removeLoading(["btnPatrimonyCreate"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "updatePatrimony")
          this.openModal("patrimonyCreateUpdate");

        if (event.name == "historyPatrimony")
          this.openModal("historyPatrimony");
      },
      deep: true,
    },
  },
};
</script>
